import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

import Footer from "../components/Footer";

import DropdownMenuDemo from "../components/UserDropdown";

import illustration from "../assets/image.png";

import familyTime from "../assets/lineart/familytime.png";
import relaxAudio from "../assets/lineart/relaxaudio.png";
import collaborating from "../assets/lineart/collaborating.png";
import family from "../assets/lineart/family.png";
import games from "../assets/lineart/games.png";
import campingTrip from "../assets/lineart/campingtrip.png";
import planning from "../assets/lineart/planning.png";
import organizeSchool from "../assets/lineart/organizeschool.png";

import arrow from "../assets/arrow.png";

import gameslist from "../assets/games.png";
import WordSearchIcon from "../assets/wordsearch.png";

import bookicon1 from "../assets/icons/books.png";
import bookicon2 from "../assets/icons/booksglobal.png";
import bookicon3 from "../assets/icons/bookclub.png";
import bookicon4 from "../assets/icons/booklibrary.png";

/*<input type="email" id="default-email" className="outline-none block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your email here..." required />*/

// {/*<img src={authenticated.picture} className="w-10 h-10 rounded-full ml-auto" />*/}

function Landing() {
	const { authenticated, login, logout } = useAuth();

	/*if (authenticated.authenticated) {
        window.location = "/home";
    }*/

	function toHome() {
		window.location = "/home";
	}

	return (
		<div className="bg-white">
			{/* <nav className="absolute top-0 bg-transparent z-[100] flex flex-wrap flex-row w-screen justify-between text-slate-50 gap-3 px-6 py-3 pt-[1.2rem] text-xl items-center border-none">

                <div to="/" className="flex flex-row gap-2 items-center text-[1.7rem] w-screen">
                    <span className="material-symbols-outlined text-[2.3rem] text-emerald-400">
                        local_library
                    </span>

                    <span>
                        nerded
                    </span>

                    {authenticated.authenticated ?
                        <DropdownMenuDemo />
                        :
                        <div onClick={login} className="ml-auto flex items-center justify-center w-fit cursor-pointer font-medium">
                            <span className="material-symbols-outlined text-[2rem] hover:text-emerald-500 transition">
                                login
                            </span>
                        </div>
                    }
                </div>
            </nav> */}

			<div className="w-screen absolute top-0 bg-white z-[100] flex justify-center items-center">
				<nav className="fixed w-screen px-[2rem] flex flex-wrap flex-row justify-between top-0 bg-white text-zinc-800 gap-3 pt-[1rem] text-xl items-center">
					<div
						to="/"
						className="flex flex-row gap-3 items-center w-screen"
					>
						<div className="bg-blue-800/10 border-2 border-blue-700 h-[50px] w-[50px] flex items-center justify-center rounded-lg p-2">
							<span className="material-symbols-outlined leading-none text-[2rem] text-blue-600">
								local_library
							</span>
						</div>

						<span className="text-zinc-900 text-[1.8rem]">
							nerded
						</span>

						{authenticated.authenticated ? (
							<DropdownMenuDemo />
						) : (
							<div
								onClick={login}
								className="ml-auto flex items-center justify-center w-fit cursor-pointer font-medium"
							>
								<span className="material-symbols-outlined text-[2rem] hover:text-sky-800 text-zinc-900 transition">
									login
								</span>
							</div>
						)}
					</div>

					<div className="border-b border-b-zinc-200 min-w-[98dvw] h-[1rem] mt-[-1rem] ml-[-1dvw] mx-auto pt-[1rem]"></div>
				</nav>
			</div>

			{/* bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')] */}
			<section className="pt-[0rem] pb-[1.6rem] bg-white">
				<div className="py-5 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
					<div className="rounded-[4rem] px-[0rem] py-[3rem]">
						{/* <a
							href="#"
							className="inline-flex justify-between items-center py-1 px-1 pe-4 mb-7 text-sm rounded-full bg-blue-900 text-blue-300 hover:bg-blue-800"
						>
							<span className="text-xs bg-blue-600 rounded-full text-white px-4 py-1.5 me-3">
								New
							</span>{" "}
							<span className="text-md font-medium">
								Nerded was just launched!
							</span>
							<svg
								className="w-2.5 h-2.5 ms-2 rtl:rotate-180"
								aria-hidden="true"
								xmlns="https://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 6 10"
							>
								<path
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="m1 9 4-4-4-4"
								/>
							</svg>
						</a> */}

						<div className="flex flex-col md:flex-row mt-[3rem] border border-zinc-200 sm:mt-[3rem] lg:mt-[1rem] justify-between h-[67dvh] min-h-fit items-center bg-white shadow-lg rounded-t-[2rem] rounded-b-[2rem] md:rounded-b-[1rem] md:rounded-[1rem]">
							<div className="flex flex-col mt-[5rem] md:ml-auto md:mt-0">
								<h1 className="mb-4 header-title text-3xl font-semibold text-zinc-900 md:text-4xl lg:text-5xl">
									Find your next favorite
									<br />
									<div className="mt-[5px]">
										read on{" "}
										<span className="text-sky-700 underline">
											Nerded
										</span>
									</div>
								</h1>
								<div className="text-lg text-zinc-900 font-normal mb-6 mt-2">
									Connect, Discover, and Conquer the World
									<br />
									of Books with Friends.
								</div>
								{/* <div className="mb-8 text-lg border font-normal flex lg:text-xl sm:px-16 lg:px-48 text-gray-200">
									Connect, Discover, and Conquer the World of Books with Friends. Explore, Review, and Challenge Yourself - Your Reading Journey Starts Here!
								</div> */}
								<div className="max-h-[4rem] flex flex-row ml-auto mr-auto w-fit h-fit border border-transparent">
									<div
										onClick={toHome}
										className="select-none text-white w-fit cursor-pointer font-medium rounded-lg text-md px-5 py-3 bg-sky-600/90 transition hover:bg-sky-600"
									>
										Open Nerded
									</div>
								</div>
							</div>

							{/* <img
								src={illustration}
								className="w-[30rem] mx-auto mt-[-2rem]"
							/> */}

							<div className="grid grid-cols-2 gap-[1rem] py-[2rem] md:py-0 mx-auto">
								<div className="transition text-zinc-900 rounded-[1rem] p-6 flex backdrop-blur-md flex-row items-center border border-zinc-800/40">
									<div>
										<img
											src={bookicon1}
											className="w-[6rem]"
										/>
									</div>
								</div>

								<div className="transition text-zinc-900 rounded-[1rem] p-6 flex backdrop-blur-md flex-row items-center border border-zinc-800/40">
									<div>
										<img
											src={bookicon2}
											className="w-[6rem]"
										/>
									</div>
								</div>

								<div className="transition text-zinc-900 rounded-[1rem] p-6 flex backdrop-blur-md flex-row items-center border border-zinc-800/40">
									<div>
										<img
											src={bookicon3}
											className="w-[6rem]"
										/>
									</div>
								</div>

								<div className="transition text-zinc-900 rounded-[1rem] p-6 flex backdrop-blur-md flex-row items-center border border-zinc-800/40">
									<div>
										<img
											src={bookicon4}
											className="w-[6rem]"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="bg-gradient-to-b to-transparent from-zinc-100 w-full h-[70dvh] absolute top-0 left-0 z-0"></div> */}
			</section>

			<div className="rounded-[2rem] rounded-b-none mt-[-4rem] flex flex-row flex-wrap gap-[2rem] justify-center p-[2rem] mx-auto w-screen border-t bg-white border-t-zinc-200 h-fit">
				<div className="flex flex-col xl:flex-row gap-[2rem] justify-center items-center">
					<div className="h-fit w-fit text-zinc-900 rounded-[1rem] shadow-xl p-6 lg:p-4 bg-white flex flex-col sm:flex-row items-center border border-zinc-200">
						<div>
							<img
								src={familyTime}
								className="min-w-[8rem] max-w-[15rem]"
							/>
						</div>

						<div className="flex flex-col gap-4">
							<div className="text-[1.2rem] pr-6">
								Connect with friends and family and share your
								reading experiences. Share your favorite books,
								discover new ones, and challenge each other to
								read more!
							</div>

							<Link
								to="https://nerded.io/home"
								className="flex mb-[0.5rem] md:mb-[-1.5rem] flex-row gap-2 items-center border border-zinc-200 p-[2px] px-[0.5rem] rounded w-fit transition hover:bg-zinc-100 cursor-pointer"
							>
								<div className="">Open Nerded</div>
								<span className="material-symbols-outlined mb-[-1px] text-[2rem] text-zinc-900">
									trending_flat
								</span>
							</Link>
						</div>
					</div>

					<div className="h-fit w-fit text-zinc-900 rounded-[1rem] shadow-xl p-6 lg:p-4 bg-white flex flex-col sm:flex-row items-center border border-zinc-200">
						<div>
							<img
								src={collaborating}
								className="min-w-[5rem] max-w-[15rem]"
							/>
						</div>

						<div className="flex flex-col gap-4">
							<div className="text-[1.2rem] pr-6">
								Create book clubs with other readers, friends,
								or family members. Set reading goals,
								participate in discussions, and share your
								thoughts with like-minded readers.
							</div>

							<Link
								to="https://nerded.io/bookclubs"
								className="flex mb-[0.5rem] md:mb-[-1.5rem] flex-row gap-2 items-center border border-zinc-200 p-[2px] px-[0.5rem] rounded w-fit transition hover:bg-zinc-100 cursor-pointer"
							>
								<div className="">Book Clubs</div>
								<span className="material-symbols-outlined mb-[-1px] text-[2rem] text-zinc-900">
									trending_flat
								</span>
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-col pb-8 pt-[4rem] gap-2 max-w-[85dvw] mx-auto">
				<div className="mb-4 flex flex-row">
					<div className="flex flex-col gap-2">
						<div className="text-[3rem] w-full flex justify-start font-semibold text-zinc-800 mx-auto">
							Daily Games
						</div>

						<p className="text-[1.2rem] w-full flex justify-start font-normal text-zinc-600 mx-auto">
							Play games and challenge yourself daily with our
							collection
							<br />
							of fun and interactive games.
						</p>
					</div>
				</div>

				<div className="w-fit flex max-h-fit text-zinc-900 rounded-[1rem] flex-row items-center">
					<div className="flex flex-row flex-wrap lg:flex-nowrap gap-[2rem]">
						<div className="flex flex-row flex-wrap col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-1 2xl:col-span-1 mx-auto gap-4 bg-white justify-center p-3 w-[17rem] rounded-lg border border-zinc-400 shadow-md">
							<div className="flex flex-col min-w-[15rem] gap-2 items-center w-full">
								<h2 className="text-xl text-zinc-900 mt-1">
									Word Search
								</h2>

								<div className="grid grid-cols-3 gap-2">
									<div className="w-[50px] h-[50px] flex items-center justify-center bg-emerald-200 text-emerald-700 p-3 rounded">
										W
									</div>
									<div className="w-[50px] h-[50px] flex items-center justify-center p-3 bg-emerald-200 text-emerald-700 rounded">
										O
									</div>
									<div className="w-[50px] h-[50px] flex items-center justify-center bg-zinc-200 p-3 rounded">
										R
									</div>
									<div className="w-[50px] h-[50px] flex items-center justify-center bg-zinc-200 p-3 rounded">
										D
									</div>
									<div className="w-[50px] h-[50px] flex items-center justify-center p-3 bg-emerald-200 text-emerald-700 rounded">
										S
									</div>
									<div className="w-[50px] h-[50px] flex items-center justify-center bg-emerald-200 text-emerald-700 p-3 rounded">
										E
									</div>
									<div className="w-[50px] h-[50px] flex items-center justify-center p-3 bg-emerald-200 text-emerald-700 rounded">
										A
									</div>
									<div className="w-[50px] h-[50px] flex items-center justify-center bg-zinc-200 p-3 rounded">
										R
									</div>
									<div className="w-[50px] h-[50px] flex items-center justify-center bg-zinc-200 p-3 rounded">
										C
									</div>
								</div>

								<Link
									to="/games/wordsearch"
									className="text-zinc-900 flex flex-row gap-2 mt-auto rounded-full py-2 w-full justify-center items-center px-4 border border-slate-700 transition hover:border-slate-700 hover:bg-zinc-300/50 cursor-pointer"
								>
									Play
								</Link>
							</div>
						</div>

						<div className="flex flex-row col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-1 2xl:col-span-1 mx-auto gap-4 bg-white justify-center p-3 w-[17rem] rounded-lg border border-zinc-400 shadow-md">
							<div className="flex flex-col min-w-[15rem] gap-2 items-center w-full">
								<h2 className="text-xl text-zinc-900 mt-1">
									Cover Jigsaw
								</h2>

								<div className="grid grid-cols-3 min-w-[15rem] gap-0">
									<div></div>
									<span className="material-symbols-outlined text-[5rem] text-zinc-800 font-[300] mb-[-1.9rem] flex justify-center">
										extension
									</span>
									<span className="material-symbols-outlined text-[5rem] text-zinc-800 font-[300] mb-[-1.9rem] ml-[-3.7rem] flex justify-center">
										extension
									</span>
									<span className="material-symbols-outlined text-[5rem] text-zinc-800 font-[300] mr-[-3.8rem] flex justify-center">
										extension
									</span>
									<span className="material-symbols-outlined text-[5rem] text-zinc-800 font-[300] flex justify-center">
										extension
									</span>
									<span className="material-symbols-outlined text-[5rem] text-zinc-800 font-[300] ml-[-3.8rem] flex justify-center">
										extension
									</span>
									<div></div>
									<span className="material-symbols-outlined text-[5rem] text-zinc-800 font-[300] mt-[-1.9rem] flex justify-center">
										extension
									</span>
									<div></div>
									<div></div>
								</div>

								<Link
									to="/games/coverpuzzle"
									className="flex text-zinc-900 flex-row gap-2 mt-auto rounded-full py-2 w-full justify-center items-center px-4 border border-slate-700 transition hover:border-slate-700 hover:bg-zinc-300/50 cursor-pointer"
								>
									Play
								</Link>
							</div>
						</div>

						<div className="flex flex-row col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-1 2xl:col-span-1 mx-auto gap-4 bg-white justify-center p-3 w-[17rem] rounded-lg border border-zinc-400 shadow-md">
							<div className="flex flex-col gap-2 min-w-[15rem] items-center w-full">
								<h2 className="text-xl text-zinc-900 mt-1">
									Spine Stacker
								</h2>

								<div className="flex flex-col gap-[4px] w-[85%] justify-end h-full mb-2 items-center">
									<div className="flex flex-row gap-1 bg-blue-600/80 h-[2rem] p-2 w-[90%] mr-[5%] rounded py-3"></div>
									<div className="flex flex-row gap-1 bg-yellow-600/80 h-[2rem] p-2 w-[90%] ml-[5%] rounded py-3"></div>
									<div className="flex flex-row gap-1 bg-green-600/80 h-[2rem] p-2 w-[90%] mr-[5%] rounded py-3"></div>
									<div className="flex flex-row gap-1 bg-red-600/80 h-[2rem] p-2 w-[90%] ml-[10%] rounded py-3"></div>
								</div>

								<Link
									to="/games/spinestacker"
									className="flex text-zinc-900 flex-row gap-2 mt-auto rounded-full py-2 w-full justify-center items-center px-4 border border-slate-700 transition hover:border-slate-700 hover:bg-zinc-300/50 cursor-pointer"
								>
									Play
								</Link>
							</div>
						</div>

						<div className="hidden lg:flex flex-row gap-[1rem] mt-[-21.5rem] items-center">
							<img
								src={arrow}
								className="select-none w-[80px] rotate-[220deg] opacity-90"
							/>
							<div className="text-black text-[1.5rem] ml-2 mt-4 opacity-90">
								you can play these!!
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-col pb-8 pt-[1rem] max-w-[85dvw] mx-auto">
				<div className="flex flex-col-reverse lg:flex-row justify-between items-center">
					<div className="flex flex-col gap-2">
						<div className="lg:mb-4 mb-[-4rem] flex flex-row items-center justify-between">
							<div className="flex flex-col gap-2">
								<div className="text-[3rem] w-full flex justify-start font-semibold text-zinc-800 mx-auto">
									Reading Challenges
								</div>

								<p className="text-[1.2rem] lg:max-w-[50vw] w-full flex justify-start font-normal text-zinc-600 mx-auto">
									Create reading challenges to stay motivated
									and reach your reading goals. Track your
									progress, earn rewards, and connect with
									other readers.
								</p>
							</div>
						</div>

						<div className="w-fit flex max-h-fit lg:mt-4 text-zinc-900 rounded-[1rem] flex-row items-center justify-start">
							<div className="flex flex-row overflow-hidden justify-start max-h-[5rem] mt-[6rem] lg:mt-0 flex-wrap gap-[2rem]">
								<div>
									<div className="text-white h-fit w-fit rounded-full border-[2px] bg-sky-500/20 p-3 border-sky-600 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center">
										<span className="material-symbols-outlined text-sky-500 text-[1.8rem] lg:text-[3.2rem] rounded-full font-normal">
											local_fire_department
										</span>
									</div>
								</div>

								<div>
									<div className="text-white h-fit w-fit rounded-full border-[2px] bg-amber-500/20 p-3 border-amber-600 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center">
										<span className="material-symbols-outlined text-amber-500 text-[1.8rem] lg:text-[3.2rem] rounded-full font-normal">
											local_fire_department
										</span>
									</div>
								</div>

								<div>
									<div className="text-white h-fit w-fit rounded-full border-[2px] bg-amber-500/20 p-3 border-amber-600 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center">
										<span className="material-symbols-outlined text-amber-500 text-[1.8rem] lg:text-[3.2rem] rounded-full font-normal">
											local_fire_department
										</span>
									</div>
								</div>

								<div>
									<div className="text-white h-fit w-fit rounded-full border-[2px] bg-sky-500/20 p-3 border-sky-600 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center">
										<span className="material-symbols-outlined text-sky-500 text-[1.8rem] lg:text-[3.2rem] rounded-full font-normal">
											local_fire_department
										</span>
									</div>
								</div>

								<div>
									<div className="text-white h-fit w-fit rounded-full border-[2px] bg-sky-500/20 p-3 border-sky-600 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center">
										<span className="material-symbols-outlined text-sky-500 text-[1.8rem] lg:text-[3.2rem] rounded-full font-normal">
											local_fire_department
										</span>
									</div>
								</div>

								<div>
									<div className="text-white h-fit w-fit rounded-full border-[2px] bg-sky-500/20 p-3 border-sky-600 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center">
										<span className="material-symbols-outlined text-sky-500 text-[1.8rem] lg:text-[3.2rem] rounded-full font-normal">
											local_fire_department
										</span>
									</div>
								</div>
							</div>
						</div>

						<Link
							to="https://nerded.io/home"
							className="flex mb-[0.5rem] mt-[2rem] md:mb-[-1.5rem] flex-row gap-2 items-center border border-zinc-200 p-[2px] px-[0.5rem] rounded w-fit transition hover:bg-zinc-100 cursor-pointer"
						>
							<div className="text-zinc-900">Open Nerded App</div>
							<span className="material-symbols-outlined mb-[-1px] text-[2rem] text-zinc-900">
								trending_flat
							</span>
						</Link>
					</div>

					<div>
						<img
							src={planning}
							className="text-zinc-900 w-[25rem]"
						></img>
					</div>
				</div>
			</div>

			<div className="flex flex-col pb-8 pt-[1rem] max-w-[85dvw] mx-auto">
				<div className="flex flex-col-reverse lg:flex-row-reverse justify-between items-center">
					<div className="flex flex-col gap-2 max-w-[90vw]">
						<div className="lg:mb-4 mb-[-4rem] flex flex-row items-center justify-between">
							<div className="flex flex-col gap-2">
								<div className="text-[3rem] w-full flex justify-start font-semibold text-zinc-800 mx-auto">
									Expand your Vocabulary
								</div>

								<p className="text-[1.2rem] lg:max-w-[50vw] w-full flex justify-start font-normal text-zinc-600 mx-auto">
									<span>
										Learn new words - including{" "}
										<span className="text-sky-600">
											SAT
										</span>{" "}
										vocab - and expand your vocabulary with
										our vocab quizzes and games.
									</span>
								</p>
							</div>
						</div>

						<div className="flex flex-col items-center p-4 shadow-xl border border-zinc-300 rounded-[2rem] mt-[5rem] lg:mt-0">
							<div className="min-w-fit gap-[1rem] flex max-h-fit lg:mt-3 text-zinc-900 rounded-[1rem] flex-row items-center justify-start">
								<div className="w-[35rem] max-w-[50dvw] h-[1.5rem] rounded-full bg-zinc-200">
									<div
										className="bg-sky-700 rounded-full h-full pt-[4px] transition-width duration-500 ease-out"
										style={{ width: `${60}%` }}
									>
										<div
											className="bg-sky-400 opacity-15 rounded-full h-[5px]"
											style={{
												width: "calc(100% - 24px)", // Full width minus margins
												marginLeft: "12px", // Equal margin on the left
												marginRight: "12px", // Equal margin on the right
											}}
										></div>
									</div>
								</div>

								<div
									className={`w-[5rem] max-h-[3.5rem] bg-red-950 p-3 pr-[14px] rounded-lg gap-3 text-red-400 flex flex-row items-center justify-center`}
								>
									<div className="text-[1.5rem] flex items-center">
										<ion-icon name="heart"></ion-icon>
									</div>
									<span className="font-semibold text-[1.2rem]">
										3
									</span>
								</div>
							</div>

							<div className="flex flex-col gap-3 px-4 mb-[1rem] w-full mt-[1.5rem]">
								<div className="p-3 px-4 rounded-lg flex flex-row gap-[1rem] items-center border border-zinc-200">
                                    <div className="w-[35px] h-[35px] rounded-full bg-sky-800 flex justify-center items-center">
                                        A
                                    </div>
                                    <div className="flex flex-col">
									<div className="text-[1.2rem] font-semibold text-zinc-900">
										SAT Vocab
									</div>
									<div className="text-[0.8rem] text-zinc-600">
										Learn and master SAT vocabulary words.
									</div>
                                    </div>
								</div>

                                <div className="p-3 px-4 rounded-lg flex flex-row gap-[1rem] items-center border border-zinc-200">
                                    <div className="w-[35px] h-[35px] rounded-full bg-sky-800 flex justify-center items-center">
                                        B
                                    </div>
                                    <div className="flex flex-col">
									<div className="text-[1.2rem] font-semibold text-zinc-900">
										AP & IB Analysis Vocab
									</div>
									<div className="text-[0.8rem] text-zinc-600">
                                        Study and learn AP & IB analysis vocabulary.
									</div>
                                    </div>
								</div>

                                <Link
                                    to="https://nerded.io/home"
                                    className="flex mb-[-10px] mt-[5px] flex-row gap-2 items-center border border-zinc-200 p-[2px] px-[0.5rem] rounded w-fit transition hover:bg-zinc-100 cursor-pointer"
                                >
                                    <div className="text-zinc-900">Nerded Vocab</div>
                                    <span className="material-symbols-outlined mb-[-1px] text-[2rem] text-zinc-900">
                                        trending_flat
                                    </span>
                                </Link>
							</div>
						</div>
					</div>

					<div>
						<img
							src={organizeSchool}
							className="text-zinc-900 w-[25rem]"
						></img>
					</div>
				</div>
			</div>

			{/* <div className="max-h-[17.5rem] w-fit flex h-fit text-zinc-900 rounded-[1rem] shadow-xl p-4 bg-white flex-row items-center border border-zinc-200">
                <div>
                    <img src={relaxAudio} className="w-[15rem]" />
                </div>
            </div> */}

			{/* <div className="bg-gradient-to-t from-nord-900 to-transparent mt-[-4rem] h-[4rem]"></div> */}

			<div className="bg-nord-900 mb-[-3rem] pb-[3rem]">
				<div className="w-screen h-[3rem] rounded-b-[3rem] bg-white"></div>
			</div>

			<Footer line={false} />

			<span className="px-[1.2rem] w-full flex justify-end mt-[-2rem] right-2 text-[0.8rem] text-slate-400">
				Art from{" "}
				<a
					href="https://www.freepik.com"
					target="_blank"
					className="px-[3px]"
				>
					Freepik
				</a>{" "}
				&
				<a
					href="https://www.flaticon.com"
					target="_blank"
					className="pl-[3px]"
				>
					Flaticon
				</a>
			</span>
		</div>
	);
}

export default Landing;
