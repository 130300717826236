import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import BookCard from "./BookCard";

function BookGrid(props) {
    // check that they all have a volumeInfo.imageLink value, if not remove.
    // do a get type of thinf so it doesnt error if it doenst have it

    /*useEffect(
        () => {
            if (props.books.length !== 0) {
                props.books.forEach((book) => {
                    try {
                        if (book.volumeInfo.imageLinks.thumbnail) {
                            setNewBooks((newBooks) => [...newBooks, book]);
                            console.log(book);
                        }
                    } catch (err) {
                        console.log(err);
                    }
                })
            }
        }, [props]
    )*/

    //console.log(props.books);



    if (props.books === undefined || props.books.length === 0) {
        return (
            <div className="flex flex-col">
                <p className="text-slate-400 text-center">No books found.</p>
                <Link className="text-sky-400 text-center hover:underline" to="/home">Back to home</Link>
            </div>
        )
    }

    console.log(props.books)

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 py-4 w-[90vw] ml-auto mr-auto">
            {(props.books.length !== 0) ? props.books.map((book, index) => (
                <BookCard
                    key={index}
                    title={book.volumeInfo.title}
                    year={book.volumeInfo.publishedDate}/*}.length > 1) ? book.publish_year[0] : book.publish_year}`*/
                    author={book.volumeInfo.authors} /* .split("-")[0] .toString().replace("[", "").replace("]", "") */
                    cover={book.volumeInfo.imageLinks?.thumbnail || null}
                    lang={book.volumeInfo.language}
                    isbn={book?.volumeInfo?.industryIdentifiers?.[0]?.identifier ?? book.volumeInfo.industryIdentifiers}
                    //isbn={book._id}
                    readList={props.readList}
                    bookmarkedList={props.bookmarkedList}
                    bookData={book.data}
                    reviewedList={props.reviewedList}
                    mature={book.volumeInfo.maturityRating}
                    refreshReviewsList={props.refreshReviewsList}
                />

            )) :
            <div>
                <p className="text-slate-400 text-center">Searching...</p>
            </div>}

        </div>
    )
}

export default BookGrid;
