import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
  } from "../ui/carousel"

import FeedBookItem from "./FeedBookItem"

export default function RecommendedCarousel() {
    return (
        <Carousel>
            <CarouselContent className="">

                <CarouselItem className="sm:basis-1/1 md:basis-1/1 lg:basis-1/2 xl:basis-1/2">
                    <FeedBookItem
                        img="https://books.google.com/books/content?id=yhIRBwAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=natural&source=gbs_api"
                        title="Six of Crows"
                        author="Leigh Bardugo"
                        description="Six of Crows is a young adult fantasy novel that follows a group of six outcasts as they attempt to pull off an impossible heist. Set in the Grishaverse, the story is full of action, adventure, and magic."
                        link="/book?isbn=9781780622293"
                    />
                </CarouselItem>

                <CarouselItem className="sm:basis-1/1 md:basis-1/1 lg:basis-1/2 xl:basis-1/2">
                    <FeedBookItem
                        img="https://books.google.com/books/content?id=dI6cEAAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api"
                        title="Tweet Cute"
                        author="Emma Lord"
                        description="Tweet Cute is a young adult contemporary romance about Pepper and Jack, high schoolers who manage their family's business social media accounts. Starting as rivals, they soon fall for each other."
                        link="/book?isbn=9781250237330"
                    />
                </CarouselItem>

                <CarouselItem className="sm:basis-1/1 md:basis-1/1 lg:basis-1/2 xl:basis-1/2">
                    <FeedBookItem
                        img="https://books.google.com/books/content?id=oMXQBAAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api"
                        title="Looking For Alaska"
                        author="John Green"
                        description="Looking for Alaska is a young adult novel that follows Miles Halter as he attends Culver Creek Preparatory School. There, he meets Alaska Young, a mysterious and enigmatic student who changes his life forever."
                        link="/book?isbn=9780698405875"
                    />
                </CarouselItem>

            </CarouselContent>

            <CarouselPrevious className="rounded" />
            <CarouselNext className="rounded" />

        </Carousel>
    )
}
