import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { toast } from "sonner";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

export default function LiteraryDevices() {
	const { authenticated, login, logout, signup } = useAuth();
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [progress, setProgress] = useState(0); // Starts from 0
	const [hearts, setHearts] = useState(3); // Starts with 3 hearts
	const [shake, setShake] = useState(false); // For the shake animation
	const [totalQuestionsArr, setTotalQuestionsArr] = useState([]);
	const [currentQuestion, setCurrentQuestion] = useState(null); // Initial question
	const [totalQuestions, setTotalQuestions] = useState(0);
	const [restart, setRestart] = useState(false);

	// Questions about literary devices
	const questions = {
		easy: [
			{
				question:
					"What literary device is used in the sentence: 'The wind whispered through the trees'?",
				options: [
					"Simile",
					"Metaphor",
					"Personification",
					"Alliteration",
				],
				answer: "Personification",
				explanation:
					"Personification gives human-like qualities to non-human things, like wind 'whispering'.",
				type: "multiple",
			},
			{
				question: "Which of the following is an example of a simile?",
				options: [
					"She is a shining star.",
					"As brave as a lion.",
					"The leaves danced.",
					"He was a rock.",
				],
				answer: "As brave as a lion.",
				explanation:
					"A simile compares two things using 'like' or 'as', like 'As brave as a lion.'",
				type: "multiple",
			},
			// Add more questions...
		],
		medium: [
			{
				question:
					"Which literary device is used in: 'Peter Piper picked a peck of pickled peppers'?",
				options: [
					"Assonance",
					"Metaphor",
					"Personification",
					"Alliteration",
				],
				answer: "Alliteration",
				explanation:
					"Alliteration repeats the initial consonant sounds, as seen with 'Peter Piper picked'.",
				type: "multiple",
			},
			{
				question:
					"What is the literary device in the sentence: 'The classroom was a zoo'?",
				options: ["Simile", "Metaphor", "Personification", "Hyperbole"],
				answer: "Metaphor",
				explanation:
					"A metaphor directly compares two things without using 'like' or 'as', such as comparing a classroom to a zoo.",
				type: "multiple",
			},
			// Add more questions...
		],
		hard: [
			{
				question:
					"What literary device is used when a part of something represents the whole, as in 'all hands on deck'?",
				options: ["Metonymy", "Synecdoche", "Hyperbole", "Irony"],
				answer: "Synecdoche",
				explanation:
					"Synecdoche uses a part to represent the whole, like 'hands' representing sailors.",
				type: "multiple",
			},
			{
				question:
					"Identify the literary device: 'The silence was deafening.'",
				options: ["Oxymoron", "Paradox", "Irony", "Hyperbole"],
				answer: "Oxymoron",
				explanation:
					"An oxymoron combines contradictory terms, like 'deafening silence'.",
				type: "multiple",
			},
			// Add more questions...
		],
	};

	// useEffect to initialize questions and shuffle them
	useEffect(() => {
		let tempQuestionsArr = [];

		for (let i = 0; i < questions.easy.length; i++) {
			tempQuestionsArr.push([questions.easy[i], "easy"]);
		}

		for (let i = 0; i < questions.medium.length; i++) {
			tempQuestionsArr.push([questions.medium[i], "medium"]);
		}

		for (let i = 0; i < questions.hard.length; i++) {
			tempQuestionsArr.push([questions.hard[i], "hard"]);
		}

		// Shuffle questions and limit to 8
		tempQuestionsArr = tempQuestionsArr
			.sort(() => Math.random() - 0.5)
			.slice(0, 8);

		setTotalQuestionsArr(tempQuestionsArr); // Set shuffled questions
		setTotalQuestions(tempQuestionsArr.length); // Set total number of questions
		setCurrentQuestion(tempQuestionsArr[0]); // Set initial question
	}, [restart]);

	// Function to handle answers
	function handleAnswer(selectedOption) {
		if (selectedOption === currentQuestion[0].answer) {
			toast.success("Correct!");
			setProgress((prev) => Math.min(prev + 100 / totalQuestions, 100));

			// Move to the next question
			if (currentQuestionIndex < totalQuestions - 1) {
				setCurrentQuestionIndex(currentQuestionIndex + 1);
				setCurrentQuestion(totalQuestionsArr[currentQuestionIndex + 1]);
			}
		} else {
			toast.error("Wrong answer!");
			setHearts((prev) => Math.max(prev - 1, 0)); // Decrease hearts but don't go below 0
			setShake(true); // Trigger the shake animation
			setTimeout(() => setShake(false), 500); // Stop the shake animation after 500ms

			if (hearts === 1) {
				toast.error("Game over. You've lost all your hearts.");
			}
		}
	}

	// Option button component
	function OptionButton({ text }) {
		return (
			<div>
				<button
					className="w-full bg-nord-900 border border-slate-600 text-slate-50 rounded-lg p-3 hover:bg-nord-800"
					onClick={() => handleAnswer(text)}
				>
					{text}
				</button>
			</div>
		);
	}

	// Function to determine the difficulty label color
	function getDifficultyLabel(difficulty) {
		if (difficulty === "easy") return "bg-blue-900 text-white";
		if (difficulty === "medium") return "bg-pink-900 text-white";
		if (difficulty === "hard") return "bg-red-900 text-white";
	}

	return (
		<div>
			<Navbar />

			<div className="container flex flex-row items-center justify-center gap-[1.5rem] mx-auto pl-6 pr-0 py-8">
				{/* Progress Bar */}
				<div className="w-[51rem] h-[1.5rem] rounded-full bg-nord-800">
					<div
						className="bg-sky-700 rounded-full h-full pt-[4px] transition-width duration-500 ease-out"
						style={{ width: `${progress}%` }}
					>
						<div
							className="bg-sky-400 opacity-15 rounded-full h-[5px]"
							style={{
								width: "calc(100% - 24px)", // Full width minus margins
								marginLeft: "12px", // Equal margin on the left
								marginRight: "12px", // Equal margin on the right
							}}
						></div>
					</div>
				</div>

				{/* Heart box with shake animation */}
				<div
					className={`w-[5rem] bg-red-950/50 mr-[-5rem] p-3 pr-[14px] rounded-lg gap-3 text-red-400 flex flex-row items-center justify-center ${
						shake ? "animate-shake" : ""
					}`}
				>
					<div className="text-[1.5rem] flex items-center">
						<ion-icon name="heart"></ion-icon>
					</div>
					<span className="font-semibold text-[1.2rem]">
						{hearts}
					</span>
				</div>
			</div>

			{currentQuestion && (
				<div className="pb-[3rem]">
					{/* Difficulty label */}
					<div className="flex flex-row items-center justify-center gap-4 mb-8">
						<span className="select-none font-semibold text-[1.3rem] w-fit flex justify-center">
							{currentQuestion[0].question}{" "}
							{/* Display current question */}
						</span>

						{/* Show difficulty */}
						<span
							className={`px-4 py-1 rounded-lg text-sm font-semibold ${getDifficultyLabel(
								currentQuestion[1],
							)}`}
						>
							{currentQuestion[1].charAt(0).toUpperCase() +
								currentQuestion[1].slice(1)}
						</span>
					</div>

					<div className="flex flex-col gap-4 w-[40vw] mx-auto">
						{currentQuestion[0].options.map((option, index) => (
							<OptionButton key={index} text={option} />
						))}
					</div>

					<div className={`fixed flex flex-row gap-2 ${authenticated.authenticated ? "top-[3.5rem]" : "top-[6.4rem]"} left-[1rem]`}>
						<button
							className="w-fit bg-nord-900 border border-slate-600 text-slate-300 rounded-lg p-3 mt-8 hover:bg-nord-800"
							onClick={() => {
								setProgress(0);
								setHearts(3);
								setCurrentQuestionIndex(0);
								setCurrentQuestion(totalQuestionsArr[0]);
								setRestart(!restart);
							}}
						>
							Restart
						</button>

						<Link
							to="/vocab/practice"
							className="w-fit bg-nord-900 border border-slate-600 text-slate-300 rounded-lg p-3 mt-8 hover:bg-nord-800"
						>
							Practice Vocab
						</Link>
					</div>
				</div>
			)}

			<Footer />
		</div>
	);
}
